.leaflet-tooltip.mapPrintFont {
  font-size: 12px !important;
  font-weight: bold;
  color: #ffffff !important;
  text-shadow: 1px 1px 1px black;
}
.leaflet-tooltip.mapPrint {
  padding: 0px !important;

  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
.leaflet-tooltip-left.mapPrint {
  transform-origin: right center;
  margin-left: 0px;
}
.leaflet-tooltip-right.mapPrint {
  transform-origin: left center;
  margin-left: -6px;
}
.leaflet-tooltip-center.mapPrint {
  transform-origin: center center;
}
.leaflet-tooltip-top.mapPrint {
  transform-origin: center bottom;
}
.leaflet-tooltip-bottom.mapPrint {
  transform-origin: center top;
}
.leaflet-tooltip.mapPrint:before {
  border: none !important;
}
